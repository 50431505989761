import { Form , Row , Col , DatePicker , Button , Table} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getRangeOptions } from './reports.module';
import { getWorkFlowAction } from '../../actions/reports.action';
import { getAPIDateFormat, getLocalDateAndTime } from "../../utils/date.utils";
import { loadingAction } from "../../actions/loading-actions";
import { roundNumber } from "../../utils/math.utils";
import ReactExport from "react-export-excel";
import { isValidArray } from "../../utils/utilities";

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const generateJsonToExport=(filters,workFlowSummary)=>{

    let reportsDemandDataXmlData=[];
    let totalServiceCounts = 0, totalSeatSharingServiceCount=0, totalSmsQty = 0;
    let totalSmsCount = 0, totalCancelledAmount = 0, totalConfirmedAmount = 0,totalcancellationPenality=0;
    if(isValidArray(workFlowSummary)&&workFlowSummary.length>0){
        workFlowSummary.forEach((element,index) => {
            let whatsappEnabled = element.whatsappEnabled==true?"Yes":"No";
            totalServiceCounts+= element.serviceCounts;
            totalSeatSharingServiceCount+= element.seatSharingServiceCount;
            totalSmsQty+= element.smsQty;
            totalSmsCount+= element.smsCount;
            totalCancelledAmount+= element.cancelledAmount;
            totalConfirmedAmount+= element.confirmedAmount;
            totalcancellationPenality+= element.cancellationPenality;
            reportsDemandDataXmlData.push([
                index+1,element.operatorNumber,element.operatorName,
                element.confirmedAmount,element.cancelledAmount,element.cancellationPenality,
                element.serviceCounts,
                element.seatSharingServiceCount,element.smsQty,element.smsCount,whatsappEnabled
            ])
        });
        if (reportsDemandDataXmlData.length>0) {
            reportsDemandDataXmlData.push([
                '','','','','','','','','','',''
            ])
            reportsDemandDataXmlData.push(['','Total','',totalConfirmedAmount,totalCancelledAmount,
            totalcancellationPenality,totalServiceCounts,totalSeatSharingServiceCount, totalSmsCount,''])
        }
    }

    let generatedTime = getLocalDateAndTime(new Date());
    const multiDataSet = [
        {
            columns: ["Report","From Date", "To Date" , "Generated day/time"],
            data: [[
                "Billing Report",filters.fromDate,filters.toDate,generatedTime
            ]]
        },
        {
            xSteps: 0,
            ySteps: 4,
            columns: [
                "SN" ,"Operator Number" ,"Operator Name","Confirmed Amount","Cancelled Amount","Cancellation Penality",
                "No of trips","No of seat sharing trips" ,
                "SMS Count","Whatsapp Enabled"],
            data: reportsDemandDataXmlData
        }
    ];


    return (
        <ExcelFile  element={<Button>Export</Button>} filename={"billing-data-report"}>
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}

export const CostSummaryReport = (props) => {

    const [form] = Form.useForm();
    const dispatch=useDispatch();
    const [workFlowSummary,setWorkFlowSummary] = useState([]);
    const [showReport,setShowReport]=useState(false);
    const [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
    });


    const onSuccess=(data)=>{
        setWorkFlowSummary(data);
        setLoading(false);
        setShowReport(true);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setLoading(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        setFilters({...filters});
        getWorkFlowAction(fromDate,toDate,onSuccess,onFailure);
    }

    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const columns = [
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => index+1
        },
        {
            title:"Operator Number",
            dataIndex:"operatorNumber",
            key:"operatorNumber",
            sorter: (a, b) => a.operatorNumber - b.operatorNumber,
            defaultSortOrder: 'ascend',
        },
        {
            title: "Operator Name",
            dataIndex: "operatorName",
            key: "operatorName"
        },
        {
            title: "Confirmed Amount",
            dataIndex: "confirmedAmount",
            key: "confirmedAmount",
            render : (text, record, index) => {
                return roundNumber(text);
            }
        },
        {
            title: "Cancelled Amount",
            dataIndex: "cancelledAmount",
            key: "cancelledAmount",
            render : (text, record, index) => {
                return roundNumber(text);
            }
        },
        {
            title: "Cancellation Penality",
            dataIndex: "cancellationPenality",
            key: "cancellationPenality",
            render : (text, record, index) => {
                return roundNumber(text);
            }
        },
        {
            title:"No of trips",
            dataIndex:"serviceCounts",
            key:"serviceCounts"
        },
        {
            title:"No of seat sharing trips",
            dataIndex:"seatSharingServiceCount",
            key:"seatSharingServiceCount"
        },
        {
            title: "SMS Count",
            dataIndex: "smsCount",
            key: "smsCount"
        },
        {
            title: "Whatsapp Enabled",
            dataIndex: "whatsappEnabled",
            key: "whatsappEnabled",
            render : (text, record, index) => {
                return text==true?"Yes":"No";
            }
        },
        {
            title: "Confirmed Fare in Maven",
            dataIndex: "mavenConfirmedFare",
            key: "mavenConfirmedFare",
            render : (text, record, index) => {
                return roundNumber(text);
            }
        },
        {
            title: "50% of Maven penalty",
            dataIndex: "mavenCancellationPenality",
            key: "mavenCancellationPenality",
            render : (text, record, index) => {
                return roundNumber(text);
            }
        },
        {
            title: "Driver app subscriptions",
            dataIndex: "driverSubscriptions",
            key: "driverSubscriptions",
        },
        {
            title: "No of Vechicles",
            dataIndex: "uniqueVechicleIds",
            key: "uniqueVechicleIds",
            render : (text, record, index) => {
                if(isValidArray(text)){
                    return text.length
                }else{
                    return 0
                }
            }
        }

    ];

  
      return (
          <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Get</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport&&<Row>
                    <Col>
                        {
                            generateJsonToExport(filters,workFlowSummary)
                        }
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                            <Table 
                                columns={columns}
                                dataSource={workFlowSummary}
                                className="bordered-table"
                                pagination={false}
                                summary={(pageData ) => {
                                    let totalServiceCounts = 0;
                                    let totalSeatSharingServiceCount=0;
                                    let totalSmsCount = 0;
                                    let totalCancelledAmount = 0;
                                    let totalConfirmedAmount = 0;
                                    let totalcancellationPenality = 0;
                                    let totalmavenConfirmedFare = 0 ;
                                    let totalmavenCancellationPenality = 0 ;
                                    let totalDriverSubscriptions = 0;
                                    let totalUniqueVechicleIds = 0
                                    pageData.forEach(({ uniqueVechicleIds,mavenConfirmedFare,mavenCancellationPenality,serviceCounts, driverSubscriptions,seatSharingServiceCount, smsCount, cancelledAmount, confirmedAmount,cancellationPenality}) => {
                                        totalServiceCounts+= serviceCounts;
                                        totalSeatSharingServiceCount+= seatSharingServiceCount;
                                        totalSmsCount+= smsCount;
                                        totalCancelledAmount+= cancelledAmount;
                                        totalConfirmedAmount+= confirmedAmount;
                                        totalcancellationPenality+= cancellationPenality;
                                        totalmavenConfirmedFare+=mavenConfirmedFare;
                                        totalmavenCancellationPenality+=mavenCancellationPenality;
                                        totalDriverSubscriptions+=driverSubscriptions;
                                        if(isValidArray(uniqueVechicleIds)){
                                            totalUniqueVechicleIds  = totalUniqueVechicleIds + uniqueVechicleIds.length;
                                        }
                                    });
                                      return (
                                        <>
                                          <Table.Summary.Row style= {{fontWeight: 'bold'}}>
                                            <Table.Summary.Cell colSpan= {2}></Table.Summary.Cell>
                                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalConfirmedAmount)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalCancelledAmount)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalcancellationPenality)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalServiceCounts)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalSeatSharingServiceCount)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalSmsCount)}</Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>{roundNumber(totalmavenConfirmedFare)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{roundNumber(totalmavenCancellationPenality)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{totalDriverSubscriptions}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{totalUniqueVechicleIds}</Table.Summary.Cell>
                                          </Table.Summary.Row>
                                        </>
                                      );
                                }}
                            />
                    </Col>
                </Row>
          </div>
      )
  }
            

      
